/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import ModalContactForm from "../components/modal-contact-form/modal-contact-form"
import { Button } from "../components/button/button"
import { linkBucketStyles } from "../components/site-footer/site-footer"
import Link from "../components/link/link"
import LinkedInIcon from "../images/linkedin.png"
import TwitterIcon from "../images/twitter.png"
import YouTubeIcon from "../images/youtube_social_icon_white.png"
import ContactForm from "../components/contact-form/contact-form"
import { useRef } from "react"

export default function Contact() {
  const { initialFocusRef } = useRef()
  return (
    <Layout>
      <Metadata title="Contact" />
      <Panel
        sx={{
          pt: ["3rem", "6rem", "8rem"],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

          flexDirection: "row",
        }}
      >
        <div
          sx={{
            flex: 1,
            maxWidth: "60rem",
            justifyContent: "center",
            marginLeft: "20rem",
          }}
        >
          <Themed.h1
            sx={{
              fontSize: "4rem",
              fontWeight: "normal",
              marginBottom: "2rem",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Contact Moth+Flame
          </Themed.h1>
          <ContactForm firstFieldRef={initialFocusRef} />
        </div>
        <div sx={{ flex: 1, marginLeft: "10rem", maxWidth: "30rem" }}>
          <div
            sx={{
              ...linkBucketStyles,
              justifyContent: "right",
              pt: [null, null, "2rem"],
            }}
          >
            <span>Contact info</span>
            <ul>
              <li>
                <span>
                  224 W 35th St <br /> Ste 500 PMB 322 <br />
                  New York, NY 10001
                </span>
              </li>
              <li>
                <Themed.a href="mailto:contact@mothandflamevr.com">
                  contact@mothandflamevr.com
                </Themed.a>
                <br />
                <Themed.a href="mailto:support@mothandflamevr.com">
                  support@mothandflamevr.com
                </Themed.a>
              </li>
            </ul>
          </div>
          <div
            sx={{
              ...linkBucketStyles,
              pt: [null, "3rem", "2rem"],
              mb: ["6rem", "6rem", "unset"],
            }}
          >
            <span>Connect with us</span>
            <ul>
              <li>
                <Link
                  to="https://www.linkedin.com/company/moth-flame-vr/"
                  target="_blank"
                  sx={{
                    backgroundImage: `url(${LinkedInIcon})`,
                    backgroundSize: "1.8rem",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: "0.7rem",
                    pl: "2.8rem",
                  }}
                >
                  LinkedIn
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/mothandflamevr"
                  target="_blank"
                  sx={{
                    backgroundImage: `url(${TwitterIcon})`,
                    backgroundSize: "2rem",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: "0.7rem",
                    pl: "2.8rem",
                  }}
                >
                  Twitter
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCObQyW0AlawhIKPrTe2tg8w"
                  target="_blank"
                  sx={{
                    backgroundImage: `url(${YouTubeIcon})`,
                    backgroundSize: "2rem",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: "0.8rem",
                    pl: "2.8rem",
                  }}
                >
                  YouTube
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Panel>
    </Layout>
  )
}
